// vue
import { h, ref, reactive, computed, watch, onMounted, onBeforeUnmount } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRouter } from 'vue-router';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// naive-ui
import { 
    NCheckbox,
    useNotification } from 'naive-ui';

// services
import BotsService from '@services/bots';
import LocalStorage from '@services/ts/localStorage';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const bots = useBots();

    // vars
    const { _ } = window;
    const init = ref(false);

    // i18n
    const { t } = useI18n();

    // naive-ui
    const notification = useNotification();

    // router
    const router = useRouter();

    // vars
    const actionType = ref();
    const loading = ref(false);
    const showConfirmation = ref(false);

    // storage filters
    const storeFilterKey = '___filters_bots';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            statuses: [ -1 ],
            states: [ -1 ],
            exchanges: [ -1 ],
            wallets: [ -1 ],
            apikeys: [ -1 ],
            algos: [ -1 ],
            matrix: [ -1 ],
            presets: [ -1 ],
            tags: [ -1 ],
            include_profit: false,
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });

    const filtersChanged = computed(() => {
        return filters.filters.statuses[0] !== -1
          || filters.filters.states[0] !== -1
          || filters.filters.exchanges[0] !== -1
          || filters.filters.wallets[0] !== -1
          || filters.filters.apikeys[0] !== -1
          || filters.filters.algos[0] !== -1
          || filters.filters.matrix[0] !== -1
          || filters.filters.presets[0] !== -1
          || filters.filters.tags[0] !== -1
          || filters.filters.include_profit !== false;
    });

    const actions = computed(() => [
        'bots__start',
        'bots__stop',
        'bots__pause',
        'bots__restart',
        'bots__edit',
        'bots__delete',
        'bots__stats',
        'bots__clone',
        'bots__archive',
        'bots__force_stop',
    ]);

    const columns = computed(() => {
        if (bots.columns) {
            const arr = [...bots.columns].filter(({ title }) => title);

            arr.unshift({
                key: 'checked',
                name: 'checked',
                isHidden: false,
                title() {
                    return h(NCheckbox, {
                        checked: bots.records.every(({ checked }) => checked),
                        onUpdateChecked(v) {
                            bots.records.forEach(el => el.checked = v);
                        },
                    });
                },
            });

            return arr;
        } else return [];
    });

    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.bots.filters_bots_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_algos_label,
            refs: bots.algos,
            filter: 'filters.algos',
            seeable: filters.filters.algos[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_order_grids_label,
            refs: bots.ordersMatrix,
            filter: 'filters.matrix',
            seeable: filters.filters.matrix[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_statuses_label,
            refs: refs.botStatuses,
            filter: 'filters.statuses',
            seeable: filters.filters.statuses[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_states_label,
            refs: refs.botStates,
            filter: 'filters.states',
            seeable: filters.filters.states[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.bots.filters_bots_taxonomy_label,
            refs: bots.tags,
            filter: 'filters.tags',
            seeable: filters.filters.tags[0] !== -1,
        }, {
            mode: 'filter_switch',
            value: filters.filters.include_profit,
            title: refs.localization.filters.bots.filters_bots_show_profit_commissions,
            filter: 'filters.include_profit',
            seeable: filters.filters.include_profit !== false,
        },
    ]);

    const actionButtonsDisabled = computed(() => bots.dataTable.records ? bots.dataTable.records.some(({ checked }) => checked) : false);

    const getData = async (localLoading = false) => {
        localLoading
            ? loading.value = true
            : gl.showLoading = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);

        filters.filters.include_profit_changed = bots.dataTable?.pager.filters.include_profit != filters.filters.include_profit;
        
        try {
            const result = await BotsService.get({
                pager: filters,
            });

            if (bots.dataTable) {
                Object.keys(result.data).forEach(key => {
                    if (!['columns'].includes(key)) {
                        bots.dataTable[key] = result.data[key];
                    }
                });
            } else {
                bots.dataTable = result.data;
            }

            bots.dataTable.records = bots.dataTable.records.map((el, i) => (
                {
                    key: i,
                    checked: false,
                    ...el,
                }
            ));

        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        localLoading
            ? loading.value = false
            : gl.showLoading = false;
    };

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && bots.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_bots', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
      
        if (update || canUpdate)
            getData();
    };

    const groupAction = async action => {
        gl.showLoading = true;
        showConfirmation.value = false;

        try {
            const result = await BotsService.groupAction({
                ids: [...bots.dataTable.records.filter(({ checked }) => checked).map(({ id }) => id)],
                action,
            });

            if (result) {
                if (result.postMessages)
                    result.postMessages.forEach(el => {
                        notification[el.success ? 'success' : 'error']({
                            content: el.msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });
                
                // update table
                // bots.records = bots.records.filter(({ id }) => !result.data?.ids.includes(id));
                await getData();
            }
        } catch {
            notification.error({
                content: t('errorMessage'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        bots.dataTable.records.forEach(el => el.checked = false);

        gl.showLoading = false;
    };


    const doSomething = ([ action, value ]) => {
        const fns = {
            onQuestionInfo: () => {
                // showQuestionInfo.value = true;
                // questionInfo.value = marketplace.iMentorRecords.find(({ id }) => id === value);
            },
        };

        // fns[action]();
    };

    const letsShowConfirmation = action => {
        actionType.value = action;
        showConfirmation.value = true;
    };

    const resetFilters = () => {
        filters.filters.statuses = [ -1 ];
        filters.filters.states = [ -1 ];
        filters.filters.exchanges = [ -1 ];
        filters.filters.wallets = [ -1 ];
        filters.filters.apikeys = [ -1 ];
        filters.filters.algos = [ -1 ];
        filters.filters.matrix = [ -1 ];
        filters.filters.presets = [ -1 ];
        filters.filters.tags = [ -1 ];
      
        getData();
    };

    onMounted(async () => {
        if (!bots.dataTable)
            await getData(true);

        init.value = true;
    });
  
    return {
        gl,
        bots,
        refs,
        init,
        router,
        loading,
        filters,
        actions,
        columns,
        refFilters,
        actionType,
        filtersChanged,
        showConfirmation,
        actionButtonsDisabled,
        getData,
        groupAction,
        doSomething,
        changeFilter,
        resetFilters,
        letsShowConfirmation,
    };
}