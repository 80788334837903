<template>
<section class="px-3 min-h-screen">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
        :segmented="{
          content: true,
          footer: 'soft'
        }">
        <template #default>
            <div class="border border-dashed border-gray-600 rounded-md items-center pb-4">
                <div class="flex items-center px-3">
                    <Bots__running class="fill-current w-12" />
                    <div class="notes text-xs text-left text-gray-600 dark:text-white/75 pl-4" v-html="bots.localization['bots_how_to_create_bot']"></div>
                </div>
                <n-button
                    strong
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    @click="router.push({ name: 'bots.create' })">
                    + {{ bots.localization['bot_create_button'] }}
                </n-button>
            </div>
        </template>
    </n-card>
    <div v-if="loading" class="flex h-full py-28 justify-center">
        <n-spin size="small" />
    </div>
    <super-table
        v-if="init"
        showSearch
        :filters="filters"
        :actions="actions"
        :refFilters="refFilters"
        :pager="bots.pager"
        :columns="columns"
        :records="bots.records"
        :filtersChanged="filtersChanged"
        columnsStorageName="__columns_bots"
        type="bots"
        @getData="getData"
        @doSomething="doSomething"
        @reset-filters="resetFilters"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-sm text-left mb-2 text-gray-600 dark:text-white/75">
                {{ bots.localization['available_bots_table_title'] }}
            </div>
        </template>
        <template #stats>
            <n-card
                size="small"
                :bordered="false"
                class="shadow bg-white dark:bg-darkCardBg rounded-md my-2"
                content-style="padding-bottom: 4px;"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <div class="text-[10px] text-gray-600 dark:text-white/75 text-left flex flex-wrap">
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_total'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="warning" class="ml-2">
                            <span class="text-yellow-500 font-semibold">{{ bots.dataTable.stats.total }}</span>
                        </n-tag>
                    </div>

                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_running'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="success" class="ml-2">
                            <span class="text-green-500 font-semibold">{{ bots.dataTable.stats.running }}</span>
                        </n-tag>
                    </div>
                    
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_stopped'] }}</span>
                        <n-tag round size="tiny" :bordered="false" class="ml-2">
                            <span class="font-semibold">{{ bots.dataTable.stats.stopped }}</span>
                        </n-tag>
                    </div>
                    
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_archived'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="info" class="ml-2">
                            <span class="text-blue-500 font-semibold">{{ bots.dataTable.stats.archived }}</span>
                        </n-tag>
                    </div>
                    
                    <div class="flex items-center mr-4 mb-2">
                        <span class="whitespace-nowrap">{{ bots.localization['available_bots_error'] }}</span>
                        <n-tag round size="tiny" :bordered="false" type="error" class="ml-2">
                            <span class="text-red-400 font-semibold">{{ bots.dataTable.stats.error }}</span>
                        </n-tag>
                    </div>
                </div>
            </n-card>
        </template>
        <template #actionButtons>
            <section v-if="bots.records.length" class="flex mt-4 mb-2">
                <n-button
                    strong
                    size="tiny"
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('start')">
                    {{ bots.localization['available_bots_table_mass_start_button'] }}
                </n-button>
                <n-button
                    strong
                    size="tiny"
                    class="ml-2 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('stop')">
                    {{ bots.localization['available_bots_table_mass_stop_button'] }}
                </n-button>
                <n-button
                    strong
                    size="tiny"
                    class="ml-2 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('pause')">
                    {{ bots.localization['available_bots_table_mass_pause_button'] }}
                </n-button>
                <n-button
                    strong
                    size="tiny"
                    class="ml-2 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('archive')">
                    {{ bots.localization['available_bots_table_mass_archive_button'] }}
                </n-button>
                <n-button
                    strong
                    size="tiny"
                    class="ml-2 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="letsShowConfirmation('delete')">
                    {{ bots.localization['available_bots_table_mass_delete_button'] }}
                </n-button>
            </section>
        </template>
    </super-table>

    <base-dialog-mobile
        v-model:show="showConfirmation"
        @positive-click="showConfirmation = false, groupAction(actionType)"
        @negative-click="showConfirmation = false">
        <div class="text-md" v-html="refs.localization.confirmations.bot[actionType]"></div>
    </base-dialog-mobile>
</section>
</template>

<script>
// general
import general from './general';

// components
import SuperTable from '@components/super-table/mobile.vue';
import Bots__running from '@components/svg/Bots__running.vue';

// naive-ui
import {
    NTag,
    NCard,
    NSpin,
    NButton } from 'naive-ui';

export default {
    name: 'bots-review-desktop',
    components: {
        NTag,
        NCard,
        NSpin,
        NButton,
        SuperTable,
        Bots__running,
    },
    setup(props, context) {
        
        return {
            ...general(arguments),
        };
    },
};
</script>