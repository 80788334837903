<template>
<n-card
    size="small"
    :bordered="false"
    class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
    :segmented="{
      content: true,
      footer: 'soft'
    }">
    <template #default>
        <div class="border border-dashed border-gray-600 rounded-md flex flex-col items-center py-6">
            <Bots__running class="w-24 fill-current" />
            <div class="notes text-center text-gray-600 dark:text-white/75 mt-2" v-html="bots.localization['bots_how_to_create_bot']"></div>
            <n-button
                strong
                class="mt-4 text-white/90 rounded-md"
                :color="gl.mainColor"
                @click="router.push({ name: 'bots.create' })">
                + {{ bots.localization['bot_create_button'] }}
            </n-button>
        </div>
    </template>
</n-card>
<div v-if="loading" class="flex h-full py-28 justify-center">
    <n-spin size="small" />
</div>
<super-table
    v-if="init"
    showSearch
    :filters="filters"
    :actions="actions"
    :refFilters="refFilters"
    :pager="bots.pager"
    :columns="columns"
    :records="bots.records"
    :filtersChanged="filtersChanged"
    columnsStorageName="__columns_bots"
    type="bots"
    @getData="getData"
    @doSomething="doSomething"
    @reset-filters="resetFilters"
    @applyFilters="changeFilter">
    <template #tableTitle>
        <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
            {{ bots.localization['available_bots_table_title'] }}
        </div>
    </template>
    <template #stats>
        <n-card
            size="small"
            :bordered="false"
            class="shadow bg-white dark:bg-darkCardBg rounded-lg my-3"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <div class="text-md text-gray-600 dark:text-white/75">
                <span>{{ bots.localization['available_bots_total'] }}</span>
                <n-tag round size="small" :bordered="false" type="warning" class="ml-2">
                    <span class="text-yellow-500 font-semibold">{{ bots.dataTable.stats.total }}</span>
                </n-tag>
                <span class="ml-4">{{ bots.localization['available_bots_running'] }}</span>
                <n-tag round size="small" :bordered="false" type="success" class="ml-2">
                    <span class="text-green-500 font-semibold">{{ bots.dataTable.stats.running }}</span>
                </n-tag>
                
                <span class="ml-4">{{ bots.localization['available_bots_stopped'] }}</span>
                <n-tag round size="small" :bordered="false" class="ml-2">
                    <span class="font-semibold">{{ bots.dataTable.stats.stopped }}</span>
                </n-tag>
                
                <span class="ml-4">{{ bots.localization['available_bots_archived'] }}</span>
                <n-tag round size="small" :bordered="false" type="info" class="ml-2">
                    <span class="text-blue-500 font-semibold">{{ bots.dataTable.stats.archived }}</span>
                </n-tag>

                <span class="ml-4">{{ bots.localization['available_bots_error'] }}</span>
                <n-tag round size="small" :bordered="false" type="error" class="ml-2">
                    <span class="text-red-400 font-semibold">{{ bots.dataTable.stats.error }}</span>
                </n-tag>
            </div>
        </n-card>
    </template>
    <template #actionButtons>
        <section v-if="bots.records.length" class="flex">
            <n-button
                strong
                class="text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('start')">
                {{ bots.localization['available_bots_table_mass_start_button'] }}
            </n-button>
            <n-button
                strong
                class="ml-3 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('stop')">
                {{ bots.localization['available_bots_table_mass_stop_button'] }}
            </n-button>
            <n-button
                strong
                class="ml-3 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('pause')">
                {{ bots.localization['available_bots_table_mass_pause_button'] }}
            </n-button>
            <n-button
                strong
                class="ml-3 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('archive')">
                {{ bots.localization['available_bots_table_mass_archive_button'] }}
            </n-button>
            <n-button
                strong
                class="ml-3 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('delete')">
                {{ bots.localization['available_bots_table_mass_delete_button'] }}
            </n-button>
        </section>
    </template>
</super-table>

<base-dialog-desktop
    v-model:show="showConfirmation"
    @positive-click="showConfirmation = false, groupAction(actionType)"
    @negative-click="showConfirmation = false">
    <div class="text-md" v-html="refs.localization.confirmations.bot[actionType]"></div>
</base-dialog-desktop>
</template>

<script>
// general
import general from './general';

// components
import SuperTable from '@components/super-table/desktop.vue';
import Bots__running from '@components/svg/Bots__running.vue';

// naive-ui
import {
    NTag,
    NSpin,
    NCard,
    NButton } from 'naive-ui';

export default {
    name: 'bots-review-desktop',
    components: {
        NTag,
        NSpin,
        NCard,
        NButton,
        SuperTable,
        Bots__running,
    },
    setup(props, context) {
        
        return {
            ...general(arguments),
        };
    },
};
</script>